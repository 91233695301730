import { Check, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MuiPaper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Collapsible from '../../../components/ConfigurationComponents/Collapsible';
import { Contact, Service, SiteInfo, Location } from '../../../types/site';
import { decodeGenericErrorMessage } from '../../../utils/error';
import { getCustomers } from '../../Customers/api';
import { getServicesForContract, updateSite, useAddSite, useDeleteSite } from '../api';
import ContactForm from './ContactForm';
import IpRestrictionForm from './IpRestrictionForm';
import LocationForm from './LocationForm';
import ServicesForm from './ServicesForm';
import SiteAccessForm from './SiteAccessForm';

const defaultSettings: SiteInfo = {
  ownerId: '-1',
  name: '',
  services: [],
  enable: true,
  hasPortal: true,
  allowAppInstall: true,
  contactList: [],
  location: { country: 'Sweden' },
  accessCustomers: [],
  contacts: [],
};

const Paper = ({ ...props }) => (
  <MuiPaper variant="outlined" {...props} sx={{ mt: 0, my: 3, p: 2, pt: 1 }} />
);

interface AddSiteFormProps {
  oldSite?: SiteInfo;
}

const AddSiteForm = ({ oldSite }: AddSiteFormProps) => {
  const nameRef = useRef();

  const { enqueueSnackbar: showSnack } = useSnackbar();
  const [siteInfo, setSiteInfo] = useState(oldSite || { ...defaultSettings });
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [lFriendlyUrl, setLFriendlyUrl] = useState(siteInfo.friendlyUrl || '');

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const customers = getCustomers();
  const updateSiteMutation = updateSite();
  const addSiteMutation = useAddSite();
  const deleteSiteMutation = useDeleteSite();

  const servicesToChooseFrom = getServicesForContract(siteInfo?.ownerId);

  const {
    name: siteName,
    location,
    contacts,
    enable: siteEnabled,
    hasPortal,
    allowAppInstall,
    services,
    ownerId,
    accessCustomers,
    ipRestrictionPattern,
    friendlyUrl,
  } = siteInfo;

  const changePropertyValue = (key: string) => {
    return (value: Location | string | string[] | Service[] | Contact[]) => {
      setSiteInfo({ ...siteInfo, [key]: value });
    };
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSiteInfo({ ...siteInfo, [e.target.name]: e.target.checked });
  };

  const handleSiteNameBlur = (e: React.FocusEvent) => {
    setNameError(false);
    const target = e.target as HTMLInputElement;
    if (target.value.length < 1) {
      setNameError(true);
    }
    let url = friendlyUrl || '';
    if (!siteInfo.id) {
      url = target.value
        .toLowerCase()
        .replace(
          /(\s+)|([å|Å|ä|Ä])|([ö|Ö|ø|Ø])|([^\w])/gis,
          (_: string, q1: string, q2: string, q3: string, q4: string) => {
            if (q1) return '-';
            if (q2) return 'a';
            if (q3) return 'o';
            if (q4) return '';
            return '';
          },
        )
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      setLFriendlyUrl(url);
    }
    setSiteInfo({ ...siteInfo, name: target.value, friendlyUrl: url });
  };

  const handleIpRestrictionPattern = (value: string) => {
    setSiteInfo({ ...siteInfo, ipRestrictionPattern: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (siteInfo?.contacts?.length < 1) {
      showSnack(`You'll need to add at least one contact to the site.`, { variant: 'warning' });
      return;
    }
    if (siteInfo?.id) {
      try {
        await updateSiteMutation.mutateAsync(siteInfo);
        await queryClient.invalidateQueries(['getSite', siteInfo.id]);
        await queryClient.invalidateQueries('getSites');
        showSnack(`Successfully updated site with name: ${siteInfo.name}`, {
          variant: 'success',
        });
      } catch (error) {
        const subMessage = decodeGenericErrorMessage(error);
        showSnack(`Could not update site with name: ${siteInfo.name}. ${subMessage}`, {
          variant: 'warning',
        });
        /* eslint-disable no-console */
        console.log(error);
      }
    } else {
      try {
        const result = await addSiteMutation.mutateAsync(siteInfo);
        showSnack(`Successfully added site with name: ${siteInfo.name}`, {
          variant: 'success',
        });
        console.log(result);
        await queryClient.invalidateQueries('getSites');
        navigate(`/sites/${result.data.id}`);
      } catch (error) {
        const subMessage = decodeGenericErrorMessage(error);
        showSnack(`Could not add site with name: ${siteInfo.name}. ${subMessage}`, {
          variant: 'warning',
        });
        console.log(error);
      }
    }
  };

  const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwnerError(false);
    if (e.target.value === '-1') {
      setOwnerError(true);
    }
    setSiteInfo({ ...siteInfo, ownerId: e.target.value });
  };

  const closeDialog = () => {
    setConfirmDelete(false);
  };

  const confirmDeletePrompt = () => {
    setConfirmDelete(true);
  };

  const handleDeleteSite = async () => {
    setConfirmDelete(false);
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await deleteSiteMutation.mutateAsync(siteInfo.id!);
      showSnack('Site was deleted successfully.', { variant: 'success' });
      setSiteInfo({ ...defaultSettings });
      await queryClient.invalidateQueries('getSites');
      navigate('/sites/new');
    } catch (error) {
      showSnack('Site could not be deleted, try again later.', { variant: 'warning' });
      /* eslint-disable no-console */
      console.log(error);
    }
  };

  useEffect(() => {
    if (oldSite?.id) {
      setSiteInfo(oldSite);
    }
  }, [oldSite]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5">{siteInfo?.id ? 'Edit a site' : 'Add a site'}</Typography>
      <Grid spacing={3} container direction="row" sx={{ pt: 0 }}>
        <Grid item xs={12} md={6}>
          <Paper>
            <Typography variant="h6" sx={{ mb: 2, mt: 1 }}>
              Main information
            </Typography>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Select a owner"
                    select
                    size="small"
                    value={ownerId}
                    onChange={handleOwnerChange}
                    fullWidth
                    error={ownerError}
                    id="ownerSelect">
                    <MenuItem value="-1" key="ownerSelectSelectNull">
                      Select a owner
                    </MenuItem>
                    {customers &&
                      customers.data &&
                      customers.data.map((customer) => (
                        <MenuItem value={customer.id} key={customer.id}>
                          {customer.name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <FormHelperText id="new-site-owner-helper-text">
                    Choose the owner of the site
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  inputRef={nameRef}
                  error={nameError}
                  defaultValue={siteName}
                  id="newSiteName"
                  label="Site name"
                  helperText="Fill out a valid site name"
                  required
                  onBlur={handleSiteNameBlur}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mt: 1, pl: 0.1 }}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={siteEnabled}
                      value="siteEnabled"
                      name="enable"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Should the site be accessible"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasPortal}
                      value="hasPortal"
                      name="hasPortal"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Should the site have a portal"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowAppInstall ?? true}
                      value="allowAppInstall"
                      name="allowAppInstall"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Allow users to install the PWA"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  error={false}
                  aria-readonly="true"
                  value={lFriendlyUrl}
                  disabled
                  id="friendlyUrl"
                  label="Friendly url"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper>
            <LocationForm values={location} onValueChange={changePropertyValue('location')} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <ContactForm values={contacts} onValueChange={changePropertyValue('contacts')} />
          </Paper>
          <Paper>
            <ServicesForm
              values={services}
              chooseAble={servicesToChooseFrom}
              emptyText="Choose a owner to select services"
              onValueChange={changePropertyValue('services')}
            />
          </Paper>
        </Grid>
      </Grid>
      <Box>
        <Box sx={{ borderRadius: 1, border: '1px solid rgba(0,0,0,.12)', p: 2, m: 0 }}>
          <SiteAccessForm
            values={accessCustomers}
            chooseAble={customers?.data ? customers.data : []}
            onValueChange={changePropertyValue('accessCustomers')}
            currentOwner={ownerId}
            emptyText="Select a owner to handle access to site"
          />
        </Box>
      </Box>
      <Box sx={{ borderRadius: 1, border: '1px solid rgba(0,0,0,.12)', p: 2, m: 0, marginTop: 2 }}>
        <Collapsible
          open={false}
          summary={
            <Typography component="span" variant="h6" sx={{ fontWeight: 'bold', px: 2 }}>
              IP Address restriction options
            </Typography>
          }>
          <IpRestrictionForm
            ipRestrictionPattern={ipRestrictionPattern}
            onSave={handleIpRestrictionPattern}
          />
        </Collapsible>
      </Box>
      <Box sx={{ p: 1, m: 0 }}>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          {siteInfo.id && (
            <Button
              variant="contained"
              color="error"
              onClick={confirmDeletePrompt}
              endIcon={<Delete />}>
              Delete
            </Button>
          )}
          <Button variant="contained" color="success" type="submit" endIcon={<Check />}>
            Save
          </Button>
        </Stack>
      </Box>
      <Dialog
        open={confirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete site</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this site? It can&apos;t be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="outlined" color="warning" onClick={handleDeleteSite}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddSiteForm;
