import { UseQueryResult, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { useGetServiceInfo } from '../../../../features/serviceConfiguration/api';
import { ServiceInfo, Site } from '../../../../types/site';
import { Branding } from './QRDesign';
import { MappedQR } from './IQR';
import { getCommonParams, baseUrl } from '../../../../features/serviceConfiguration/api';

/**
 * Hook to get branding information for a given site.
 * @param {Site} currentSite - The current site.
 * @returns {QueryObserverResult<Branding[], Error>} Query object containing branding data and query metadata.
 */
export const useGetBranding = (currentSite: Site) => {
  const { headers, serviceInfo } = getCommonParams('76c78918-d266-4b41-bf56-30bf469b400d');

  return useQuery<Branding[]>(
    ['branding', currentSite.id],
    async ({ queryKey }) => {
      const [, siteId] = queryKey as [string, string];
      const response = await axios.get<Branding[]>(
        `${baseUrl}${serviceInfo?.basePath}/api/branding/${siteId}`,
        { headers },
      );
      return response.data;
    },
    { enabled: !!currentSite.id && !!serviceInfo && headers !== null },
  );
};

/**
 * Hook to get the API URL.
 * @returns {string} The API URL.
 */
export const useGetApiUrl = () => {
  const { data: serviceInfo } = useGetServiceInfo<ServiceInfo>(
    '76c78918-d266-4b41-bf56-30bf469b400d',
  );
  if (!serviceInfo) {
    return;
  }
  return `${baseUrl}${serviceInfo?.basePath}/`;
};

/**
 * Hook to add a new branding.
 * @returns {MutationObserverResult<unknown, Error, Branding, unknown>} Mutation object containing mutation data and mutation metadata.
 */
export const useAddBrandingMutation = () => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    '76c78918-d266-4b41-bf56-30bf469b400d',
  );

  return useMutation(async (data: Branding) => {
    return axios.post<Branding>(
      `${baseUrl}${serviceInfo?.basePath}/api/branding/${currentSite.id}`,
      data,
      { headers },
    );
  });
};

/**
 * Hook to add a new branding.
 * @returns {MutationObserverResult<unknown, Error, Branding, unknown>} Mutation object containing mutation data and mutation metadata.
 */
export const useUpdateBrandingMutation = () => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    '76c78918-d266-4b41-bf56-30bf469b400d',
  );

  return useMutation(async (data: Branding) => {
    return axios.put<Branding>(
      `${baseUrl}${serviceInfo?.basePath}/api/branding/${currentSite.id}/${data.id}`,
      data,
      { headers },
    );
  });
};

/**
 * Hook to delete a branding.
 * @returns {MutationObserverResult<unknown, Error, string, unknown>} Mutation object containing mutation data and mutation metadata.
 */
export const useDeleteBrandingMutation = () => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    '76c78918-d266-4b41-bf56-30bf469b400d',
  );

  return useMutation(async (id: string) => {
    return axios.delete<string>(
      `${baseUrl}${serviceInfo?.basePath}/api/branding/${currentSite.id}/${id}`,
      { headers },
    );
  });
};

/**
 * This hook provides a mutation function that sends a POST request to update a QR code.
 * It uses the user's current site and service information to construct the request URL.
 * The mutation function takes a `MappedQR` object as parameter, which represents the QR code to be updated.
 *
 * @returns A tuple where the first element is the mutate function, and the second element is an object containing the mutation status.
 */
export const useUpdateQrCodeMutation = () => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    'faee5b37-5caa-4942-a134-399e8004572f',
  );

  return useMutation(async (data: MappedQR) => {
    return axios.put<MappedQR>(
      `${baseUrl}${serviceInfo?.basePath}/configuration/${currentSite.id}/qr/${data.qrCodeId}`,
      data,
      { headers },
    );
  });
};

interface GenerateNewMutationProps {
  numberToReserve: number;
  sub: string;
}

export const useGenerateNewMutation = () => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    'faee5b37-5caa-4942-a134-399e8004572f',
  );

  return useMutation(async (data: GenerateNewMutationProps) => {
    const uri = `${baseUrl}${serviceInfo?.basePath}/configuration/${currentSite.id}/qr/reserve`;
    return axios.post<string>(uri, { numberToReserve: data.numberToReserve }, { headers });
  });
};

interface QRCodesResponse {
  page: number;
  pageCount: number;
  pageSize: number;
  qrCodes: MappedQR[];
}

export enum SortBy {
  hri = 'hri',
  location = 'location',
  created = 'created',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export const useGetQrCodes = (
  page: number = 0,
  pageSize: number = 20,
  sortBy: string = SortBy.hri,
  sortOrder: string = SortOrder.asc,
): UseQueryResult<QRCodesResponse> => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    'faee5b37-5caa-4942-a134-399e8004572f',
  );

  return useQuery<QRCodesResponse>(
    ['getQrCodes', page, pageSize, sortBy, sortOrder],
    async () => {
      const reqData = await axios.get<QRCodesResponse>(
        `${baseUrl}${serviceInfo?.basePath}/configuration/${currentSite.id}/qr?page=${page}&pageSize=${pageSize}&OrderBy=${sortBy}&SortOrder=${sortOrder}`,
        {
          headers,
        },
      );
      return reqData.data;
    },
    { enabled: !!serviceInfo?.id },
  );
};

export const useBatchUpdateQRCodes = () => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    'faee5b37-5caa-4942-a134-399e8004572f',
  );

  return useMutation(async (data: MappedQR[]) => {
    const uri = `${baseUrl}${serviceInfo?.basePath}/configuration/${currentSite.id}/qr/batch`;
    return axios.put<string>(uri, data, { headers });
  });
};

export const useSearchQrCode = (
  pageSize: number = 1000,
  searchWord: string,
  aua: string,
  sortBy: SortBy = SortBy.hri,
  sortOrder: SortOrder = SortOrder.asc,
) => {
  const { headers, currentSite, serviceInfo } = getCommonParams(
    'faee5b37-5caa-4942-a134-399e8004572f',
  );

  return useQuery<QRCodesResponse>(
    ['searchQrCodes', pageSize, searchWord, aua, sortBy, sortOrder],
    async () => {
      const reqData = await axios.get<QRCodesResponse>(
        `${baseUrl}${serviceInfo?.basePath}/configuration/${currentSite.id}/qr?hri=${searchWord}&assignedStatus=${aua}&pageSize=${pageSize}&OrderBy=${sortBy}&SortOrder=${sortOrder}`,
        {
          headers,
        },
      );
      return reqData.data;
    },
  );
};
