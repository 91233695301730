import { Button, FormControl, FormLabel, Stack } from '@mui/material';
import { VisuallyHiddenInput } from '../Locations/Locations';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface FileInfo {
  file: File;
  content: string | undefined;
  isLoading?: boolean;
}

interface ImageInputProps {
  isReadOnly: boolean;
  label: string;
  imageUrl: string;
  onChange: (value: string) => void;
  updatePreview: (value: string, hide?: boolean) => void;
}

const ImageInput = ({ isReadOnly, imageUrl, updatePreview, label, onChange }: ImageInputProps) => {
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const [localImgUrl, setLocalImgUrl] = useState<string>(imageUrl);

  const readFile = (file: File) => {
    return new Promise<FileInfo>((resolve) => {
      const reader = new FileReader();
      reader.onabort = () => showSnack('Upload was aborted', { variant: 'warning' });
      reader.onerror = () => showSnack('Upload failed', { variant: 'warning' });
      reader.onload = () => {
        const binaryStr = reader.result as string;
        resolve({ file, content: binaryStr });
      };
      reader.readAsDataURL(file);
    });
  };
  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const theFile = await readFile(file);
      if (theFile.content) {
        setLocalImgUrl(theFile.content);
        onChange(theFile.content);
      }
    }
  };

  useEffect(() => {
    setLocalImgUrl(imageUrl);
  }, [imageUrl]);

  if (!localImgUrl) {
    return (
      <FormControl fullWidth>
        <FormLabel>{label}</FormLabel>
        <Button
          component="label"
          color="info"
          variant="contained"
          startIcon={<UploadFileIcon />}
          disabled={isReadOnly}>
          Upload image
          <VisuallyHiddenInput accept=".png, .jpg, .webp" type="file" onChange={handleFileUpload} />
        </Button>
      </FormControl>
    );
  }

  return (
    <>
      <FormControl fullWidth>
        <FormLabel>{label}</FormLabel>
      </FormControl>
      <Stack direction="row">
        <Button
          sx={{ flex: '1 1 auto', borderRadius: () => (!isReadOnly ? '4px 0 0 4px' : 1) }}
          color="info"
          variant="contained"
          startIcon={<PreviewIcon />}
          onClick={() => {
            updatePreview(localImgUrl);
          }}>
          Show image
        </Button>
        {!isReadOnly && (
          <Button
            color="error"
            sx={{ flex: '0 1 auto', borderRadius: '0 4px 4px 0' }}
            variant="contained"
            onClick={() => {
              setLocalImgUrl('');
              onChange('');
              updatePreview('', true);
            }}>
            <DeleteForeverIcon />
          </Button>
        )}
      </Stack>
    </>
  );
};

export default ImageInput;
