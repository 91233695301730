import { Box, Tabs, Tab, InputLabel, FormControl, TextField, Button } from '@mui/material';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { languages } from '../CustomFields/CustomFields';
import { useState } from 'react';
import { SRMetaData } from './Metadata';
import { TabPanel } from '../../Section';
import DeleteIcon from '@mui/icons-material/Delete';
import InlineValueWithCopy from '../../../../components/InlineValueWithCopy';

interface MetadataFormProps {
  mdIndex: number;
  md: SRMetaData;
  onValueChange: (mdIndex: number, lang: string, valueType: string) => (v: string) => void;
  handleDeleteMetadata: (mdIndex: number) => (e: React.MouseEvent) => void;
}

const MetadataForm = ({ mdIndex, md, onValueChange, handleDeleteMetadata }: MetadataFormProps) => {
  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const cmds = [
    commands.title3,
    commands.title4,
    commands.title5,
    commands.title6,
    commands.bold,
    commands.italic,
    commands.strikethrough,
    commands.hr,
    commands.divider,
    commands.link,
    commands.quote,
    commands.unorderedListCommand,
  ];

  return (
    <Box>
      <Tabs value={tab} onChange={handleTabChange}>
        {languages.map((lang) => {
          return <Tab label={lang} id={`tab-${lang}`} aria-controls={`tab-${lang}`} key={lang} />;
        })}
      </Tabs>

      {languages.map((lang, index) => {
        const data = md.translations.find((t) => t.locale === lang);
        return (
          <TabPanel key={`panel-${lang}`} value={tab} index={index}>
            <Box>
              <InputLabel>Title</InputLabel>
              <FormControl>
                <TextField
                  size="small"
                  value={data?.title || ''}
                  onChange={(e) => onValueChange(mdIndex, lang, 'title')(e.target.value || '')}
                  required
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Value</InputLabel>
              <FormControl>
                <MDEditor
                  value={data?.value || ''}
                  onChange={(v: string | undefined) =>
                    onValueChange(mdIndex, lang, 'value')(v || '')
                  }
                  commands={cmds}
                  extraCommands={[commands.codeEdit, commands.codeLive, commands.codePreview]}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                justifyContent: 'space-between',
                mt: 1,
              }}>
              <Button
                onClick={handleDeleteMetadata(mdIndex)}
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}>
                Delete Metadata
              </Button>

              {md.id && (
                <InlineValueWithCopy
                  label={`Id: ${md.id}`}
                  value={md.id}
                  size="small"
                  sx={{ p: 1 }}
                />
              )}
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default MetadataForm;
