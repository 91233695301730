import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import FilterPane from './FilterPane';
import GenerateNew from './GenerateNew';
import { ICategoryItem, IQRContext, MappedQR, QRProps } from './IQR';
import QRRow, { QRselectedRowsKey } from './Row';
import ThePagination from './Pagination';
import { useTabContext } from '../../Section';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import RowTools from './RowTools';
import { SortBy, SortOrder, useGetQrCodes } from './api';

const QRContext = createContext<IQRContext | null>(null);

export const useQRContext = () => {
  const ctx = useContext(QRContext);
  if (ctx === null) {
    throw new Error('qrContext must be usen within QRContextProvider');
  }
  return ctx;
};

const StyledTableTD = styled(TableCell)(() => ({
  paddingTop: '0.8rem',
  paddingBottom: '0.8rem',
}));

const QR = ({ comp }: QRProps) => {
  const { sharedData, setSharedData, changeTab } = useTabContext();
  let num: number = 10;
  if (window.localStorage) {
    const lsVal = window.localStorage.getItem('qrNumPerPage');
    if (lsVal !== null) {
      num = parseInt(lsVal, 10) || 10;
    }
  }

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(num);
  const [categoryList, setCategoryList] = useState<ICategoryItem[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [visibleValues, setVisibleValues] = useState<MappedQR[] | null>(null);
  const [filteredVals, setFilteredVals] = useState<MappedQR[]>(comp.value);
  const [mainCheckbox, setMainCheckbox] = useState<boolean>(false);
  const [printSelectedDisabled, setPrintSelectedDisabled] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.asc);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.hri);

  const { data } = useGetQrCodes(currentPage, pageSize, sortBy, sortOrder);

  const showQrPreview = (url: string) => {
    setPreviewUrl(url);
    setShowPreview(true);
  };

  const closeQrPreview = () => {
    setShowPreview(false);
  };

  const handleFilter = (vals: MappedQR[]) => {
    setCurrentPage(0);
    setFilteredVals(vals);
    setVisibleValues(vals.slice(0, pageSize));
  };

  const handlePageChange = (e: React.ChangeEvent<unknown> | null, value: number) => {
    setMainCheckbox(false);
    setCurrentPage(value);
  };

  const handleRowChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const count = parseInt(e.target.value, 10);
    if (window.localStorage) {
      window.localStorage.setItem('qrNumPerPage', count.toString());
    }
    setPageSize(count);
    setCurrentPage(0);
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkHandler(e.target.checked);
  };

  const checkHandler = (value: boolean) => {
    if (visibleValues && visibleValues.length > 0) {
      setMainCheckbox(value);
      const shared: Record<string, unknown> = { ...sharedData };
      const sharedList = (shared[QRselectedRowsKey] as MappedQR[]) || [];
      if (value) {
        //Add to shared list
        visibleValues.forEach((s) => {
          s.isSelected = true;
          const ex = sharedList.find((d) => s.qrCodeId === d.qrCodeId);
          if (!ex) {
            sharedList.push(s);
          }
        });
      } else {
        //Remove from shared list
        visibleValues.forEach((s) => {
          s.isSelected = false;
          const ex = sharedList.findIndex((d) => s.qrCodeId === d.qrCodeId);
          if (ex > -1) {
            sharedList.splice(ex, 1);
          }
        });
      }
      shared[QRselectedRowsKey] = sharedList;
      setSharedData(shared);
    }
  };

  const changeSortOrder = (orderBy: SortBy) => () => {
    setSortBy(orderBy);
    setSortOrder(SortOrder.asc);
    if (orderBy === sortBy) {
      setSortOrder(sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc);
    }
  };

  useEffect(() => {
    setPrintSelectedDisabled(true);
    if ((sharedData?.selectedRows as MappedQR[])?.length > 0) {
      setPrintSelectedDisabled(false);
    }
  }, [sharedData]);

  useEffect(() => {
    const list: ICategoryItem[] = [];
    comp.categoryTypes.forEach((d) => {
      d.categories.forEach((a) => {
        list.push({ ...a, parentId: d.id });
      });
    });
    setCategoryList(list);
  }, [comp.categoryTypes]);

  useEffect(() => {
    if (data) {
      if (sharedData) {
        const sharedList = (sharedData[QRselectedRowsKey] as MappedQR[]) || [];
        let cnt = 0;
        data.qrCodes.forEach((a) => {
          const b = sharedList.find((c) => c.qrCodeId === a.qrCodeId);
          if (b) {
            cnt++;
            a.isSelected = true;
          }
        });
        if (cnt === pageSize) {
          setMainCheckbox(true);
        }
      }
      setVisibleValues(data.qrCodes);
    }
  }, [data]);

  return (
    <QRContext.Provider
      value={{
        categories: comp.categoryTypes,
        categoryList,
        showQrPreview,
      }}>
      <Paper variant="elevation" elevation={0}>
        <Card sx={{ my: 2, p: 2 }} variant="outlined">
          <FilterPane
            onFilter={handleFilter}
            pageSize={pageSize}
            categoryList={categoryList}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
          <ThePagination
            count={filteredVals.length}
            pageSize={pageSize}
            currentPage={currentPage}
            onHandleRowChange={handleRowChange}
            onPageChange={handlePageChange}>
            <Typography variant="h6" component={'h6'} sx={{ mb: 1 }}>
              QR-Codes
            </Typography>
          </ThePagination>

          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: 'rgba(2, 136, 209, 0.3)' }}>
                <StyledTableTD align="left" padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={handleChecked}
                    checked={mainCheckbox}
                    inputProps={{
                      'aria-label': '(un)select all rows for editing',
                    }}
                  />
                </StyledTableTD>
                <StyledTableTD align="left" width="120px">
                  <Button size="small" onClick={changeSortOrder(SortBy.hri)}>
                    QR Code
                  </Button>
                </StyledTableTD>
                <StyledTableTD>
                  <Button size="small" onClick={changeSortOrder(SortBy.location)}>
                    URL/Location
                  </Button>
                </StyledTableTD>
                <StyledTableTD>Category</StyledTableTD>
                <StyledTableTD width="180px" align="right">
                  Tools
                </StyledTableTD>
              </TableRow>
              <RowTools onSave={() => checkHandler(false)} />
            </TableHead>
            <TableBody>
              {visibleValues && visibleValues?.length > 0 ? (
                visibleValues?.map((d) => {
                  return <QRRow data={d} key={d.qrCodeId} />;
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No matching rows found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Stack direction="row" spacing={4} alignItems="center">
            <Box flex="1">
              <Button
                variant="contained"
                color="info"
                onClick={() => changeTab(1)}
                disabled={printSelectedDisabled}
                startIcon={<LocalPrintshopIcon />}>
                Print selected codes
              </Button>
            </Box>
            <Box flex="1" alignSelf="flex-end">
              <ThePagination
                count={filteredVals.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onHandleRowChange={handleRowChange}
                onPageChange={handlePageChange}
              />
            </Box>
          </Stack>
        </Card>
        <Card sx={{ my: 2, p: 2 }} variant="outlined">
          <Typography variant="h6" component={'h6'} sx={{ mb: 1 }}>
            Reserve new codes
          </Typography>
          <GenerateNew />
        </Card>
      </Paper>
      <Backdrop
        transitionDuration={{ appear: 100, enter: 100, exit: 300 }}
        open={showPreview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription"
        onClick={closeQrPreview}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0,0,0,0.8)' }}>
        <img src={previewUrl} alt="QR Code Preview" />
      </Backdrop>
    </QRContext.Provider>
  );
};

export default QR;
