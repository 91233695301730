import { Box, useTheme } from '@mui/material';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useContext, useEffect, useState } from 'react';

import { FormContext, FormContextType } from '../../contexts/FormContext';
import { ComponentBase } from './ComponentChooser';
import { OptionalTooltip } from '../OptionalTooltip';

const Markdown = ({ id, comp }: ComponentBase) => {
  const { handleChange } = useContext<FormContextType>(FormContext);
  const [value, setValue] = useState(comp.value);
  const theme = useTheme();
  const cmds = [
    commands.title3,
    commands.title4,
    commands.title5,
    commands.title6,
    commands.bold,
    commands.italic,
    commands.strikethrough,
    commands.hr,
    commands.divider,
    commands.link,
    commands.quote,
    commands.unorderedListCommand,
  ];

  const onChange = (v: string | undefined) => {
    setValue(v);
    handleChange(id, v || '');
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-color-mode', theme.palette.mode);
    handleChange(id, comp.value as string);
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-color-mode', theme.palette.mode);
  }, [theme]);

  return (
    <OptionalTooltip tooltip={comp.toolTip}>
      <Box sx={{ py: 2 }}>
        <MDEditor
          value={value as string}
          onChange={onChange}
          commands={cmds}
          extraCommands={[commands.codeEdit, commands.codeLive, commands.codePreview]}
        />
      </Box>
    </OptionalTooltip>
  );
};

export default Markdown;
