import EditIcon from '@mui/icons-material/Edit';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  Checkbox,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { createContext, useContext, useState } from 'react';
import QRCategory from './Category';
import QRCustomURI from './CustomURI';
import { IQRRow, MappedQR } from './IQR';
import Location, { LocationData } from '../../Location';
import { useQRContext } from './QR';
import { useTabContext } from '../../Section';
import SaveIcon from '@mui/icons-material/Save';
import { useUpdateQrCodeMutation } from './api';
import { useSnackbar } from 'notistack';
import { Cancel } from '@mui/icons-material';
export const QRselectedRowsKey = 'selectedRows';
const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {},
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(2, 136, 209, 0.3)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export interface IRowContext {
  edit: boolean;
  toggleEdit: () => void;
  hasEdit: boolean;
  setHasEdit: (hasEdit: boolean) => void;
}
const IRowContext = createContext<IRowContext | null>(null);

export const useRowProvider = () => {
  const ctx = useContext(IRowContext);
  if (ctx === null) {
    throw new Error('useIRowContext need to be inside IRowContextProvider');
  }
  return ctx;
};

const QRRow = ({ data }: IQRRow) => {
  const { sharedData, setSharedData } = useTabContext();
  const [useCustomUrl, setUseCustomUrl] = useState<boolean>(
    data.customURI?.length > 0 ? true : false,
  );
  const { showQrPreview } = useQRContext();

  const { enqueueSnackbar: showSnack } = useSnackbar();
  const updateRowMutation = useUpdateQrCodeMutation();
  const [edit, setEdit] = useState<boolean>(false);
  const [hasEdit, setHasEdit] = useState<boolean>(false);

  const toggleUseCustomUrl = () => {
    setUseCustomUrl(!useCustomUrl);
  };
  const toggleEdit = () => {
    setEdit(!edit);
  };

  const updateRow = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await updateRowMutation.mutateAsync(data);
      showSnack('The row was updated, nice job! 😎', { variant: 'success' });
      setHasEdit(false);
      setEdit(false);
    } catch (error) {
      showSnack('Could not update row, try again. 😢', { variant: 'error' });
    }
  };

  const handleChecked = () => {
    const existing: Record<string, unknown> = { ...sharedData };
    if (data.isSelected) {
      const newList = (existing[QRselectedRowsKey] as MappedQR[])?.filter((d: MappedQR) => {
        return d.qrCodeId !== data.qrCodeId;
      });
      const a = { ...existing, [QRselectedRowsKey]: newList };
      data.isSelected = false;
      setSharedData(a);
    } else {
      //Add to list
      if (!Array.isArray(existing[QRselectedRowsKey])) {
        existing[QRselectedRowsKey] = [];
      }
      (existing[QRselectedRowsKey] as MappedQR[]).push(data);
      data.isSelected = true;
      setSharedData(existing);
    }
  };

  return (
    <IRowContext.Provider value={{ toggleEdit, setHasEdit, edit, hasEdit }}>
      <StyledTableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onChange={handleChecked}
            checked={Boolean(data.isSelected)}
            inputProps={{
              'aria-label': 'select row for editing',
            }}
          />
        </TableCell>
        <TableCell>
          <Typography sx={{ my: edit ? 1 : 0 }} variant="body1">
            {data.hri}
          </Typography>
        </TableCell>
        {useCustomUrl ? (
          <TableCell colSpan={2}>
            <QRCustomURI data={data} />
          </TableCell>
        ) : (
          <>
            <TableCell>
              <Location data={data as LocationData} edit={edit} setHasEdit={setHasEdit} />
            </TableCell>
            <TableCell>
              <QRCategory data={data} edit={edit} setHasEdit={setHasEdit} />
            </TableCell>
          </>
        )}
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {edit && (
            <FormControl>
              <IconButton onClick={toggleUseCustomUrl} color="info" aria-label="Toggle custom url">
                {useCustomUrl ? <LinkOffIcon /> : <InsertLinkIcon />}
              </IconButton>
            </FormControl>
          )}
          <FormControl>
            <IconButton
              onClick={() => {
                showQrPreview(data.imageUrl as string);
              }}
              color="default"
              aria-label="Preview qr code">
              <QrCodeScannerIcon />
            </IconButton>
          </FormControl>
          <FormControl>
            <Buttons updateRow={updateRow} />
          </FormControl>
        </TableCell>
      </StyledTableRow>
    </IRowContext.Provider>
  );
};
interface ButtonsProps {
  updateRow: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export const Buttons = ({ updateRow }: ButtonsProps) => {
  const { edit, hasEdit, toggleEdit } = useRowProvider();

  if (edit) {
    if (hasEdit) {
      return (
        <IconButton onClick={updateRow} color="success" aria-label="Save row">
          <SaveIcon />
        </IconButton>
      );
    } else {
      return (
        <IconButton onClick={toggleEdit} color="warning" aria-label="Cancel edit">
          <Cancel />
        </IconButton>
      );
    }
  }
  return (
    <IconButton onClick={toggleEdit} color="info" aria-label="Edit row">
      <EditIcon />
    </IconButton>
  );
};

export default QRRow;
