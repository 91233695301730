import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  TextField,
} from '@mui/material';
import { useTabContext } from '../../Section';
import { QRselectedRowsKey } from './Row';
import { MappedQR, PostQrCode } from './IQR';
import {
  useAddBrandingMutation,
  useDeleteBrandingMutation,
  useGetApiUrl,
  useGetBranding,
  useUpdateBrandingMutation,
} from './api';
import { useUserContext } from '../../../../contexts/userContext';
import { Site } from '../../../../types/site';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Addchart } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ImageInput from './ImageInput';
import { ActionButton } from '../../../ActionButton';
import { decodeGenericErrorMessage } from '../../../../utils/error';

interface BorderBranding {
  borderColor: string;
  text: string;
  imageUrl: string;
  textColor: string;
  textSize: number;
}
export interface Branding {
  id: string | null;
  name: string;
  backgroundColor: string;
  foregroundColor: string;
  topBorderBranding: BorderBranding;
  bottomBorderBranding: BorderBranding;
  iconUrl: string;
  iconSize: 0;
}
interface BrandingChild {
  borderColor?: string;
  text?: string;
  imageUrl?: string;
  textSize?: string;
}
export interface BrandingResponse {
  data: Branding[];
}

const QRDesign = () => {
  const apiUrl = useGetApiUrl();
  const { enqueueSnackbar: showSnack } = useSnackbar();
  const { sharedData } = useTabContext();
  const { currentSite } = useUserContext();
  const { data: brandings } = useGetBranding(currentSite as Site);

  const selectedQrCodes = (sharedData?.[QRselectedRowsKey] as MappedQR[]) || [];
  const [selectedBranding, setSelectedBranding] = useState<Branding | null>(null);
  const [localBranding, setLocalBranding] = useState<Branding | null>(null);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [usePrintingGuides, setUsePrintingGuides] = useState<boolean>(false);
  const [printPng, setPrintPng] = useState<boolean>(false);

  //Image to the left (border image preview)
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  //Image to the right (qr-code preview)
  const [previewImage, setPreviewImage] = useState<string>(
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [qrCodesToPrint, setQrCodesToPrint] = useState<string>(
    selectedQrCodes.map((d) => d.hri).join('\r\n'),
  );

  const updateMutation = useUpdateBrandingMutation();
  const deleteMutation = useDeleteBrandingMutation();
  const addMutation = useAddBrandingMutation();
  const queryClient = useQueryClient();

  const closePreview = () => {
    setShowPreview(false);
  };

  const handleUpdatePreview = (url: string, hide: boolean = false) => {
    setPreviewUrl(url);
    setShowPreview(true);
    if (hide) {
      setShowPreview(false);
    }
  };

  const fetchPdf = async () => {
    const numberOfQrCodesToPrint = qrCodesToPrint.split('\n').length;
    let doPrintPng = printPng;
    if (numberOfQrCodesToPrint > 1) {
      doPrintPng = false;
    }
    const postObj: PostQrCode = {
      keys: qrCodesToPrint.split('\n').map((d) => d.replace(/[^\w:]/g, '')),
      type: 1,
      brandingDefinition: selectedBranding?.id || null,
      responseFormat: doPrintPng ? 0 : 1,
      addPrintingGuides: usePrintingGuides,
    };
    try {
      const response = await axios.post<Blob>(`${apiUrl}api/qr`, postObj, {
        responseType: 'blob',
      });
      if (doPrintPng) {
        fileDownload(response.data, 'qr.png', 'image/png');
      } else {
        fileDownload(response.data, 'qr.pdf', 'application/pdf');
      }
    } catch (err) {
      const errorMessage = decodeGenericErrorMessage(err);
      showSnack(`Could not generate pdf. ${errorMessage}. Try again later.`, {
        variant: 'warning',
      });
    }
  };

  const handleBrandingChange = (e: SelectChangeEvent<string>) => {
    const selected = brandings?.find((b) => b.id === e.target.value);
    setIsReadOnly(false);
    if (selected?.id === '52add555-843e-42cd-bba0-b2706e8d2f3b') {
      setIsReadOnly(true);
    }
    setLocalBranding(structuredClone(selected) || null);
    setSelectedBranding(selected || null);
    void generatePreview(selected);
  };

  const createNewBranding = () => {
    const newBranding: Branding = structuredClone({
      ...brandings?.find((b) => b.id === '52add555-843e-42cd-bba0-b2706e8d2f3b'),
      name: 'New branding',
      id: null,
    }) as Branding;

    if (newBranding) {
      setIsReadOnly(false);
      setLocalBranding(newBranding);
      void generatePreview(newBranding);
    }
  };

  const handleIconSizeChange = (e: Event, value: number | number[]) => {
    if (localBranding !== null) {
      setLocalBranding({ ...localBranding, iconSize: value as number } as Branding);
    }
  };

  const handleTextSizeChange = (prop: keyof Branding) => {
    return (e: Event, value: number | number[]) => {
      if (localBranding !== null) {
        const a = {
          ...localBranding,
          [prop]: { ...(localBranding[prop] as BrandingChild), textSize: value },
        };
        setLocalBranding(a);
      }
    };
  };

  const handleChange = <T,>(property: string) => {
    if (property.match(/\./)) {
      const [parent, child] = property.split('.');
      return (e: T) => {
        const realVal: string =
          typeof e === 'string' ? e : (e as React.ChangeEvent<HTMLInputElement>).target.value;

        if (localBranding !== null) {
          const b = localBranding[parent as keyof Branding] as BrandingChild;
          setLocalBranding({
            ...localBranding,
            [parent]: { ...b, [child]: realVal },
          } as Branding);
        }
      };
    }

    return (e: T) => {
      const realVal: string =
        typeof e === 'string' ? e : (e as React.ChangeEvent<HTMLInputElement>).target.value;
      setLocalBranding({
        ...localBranding,
        [property]: realVal,
      } as Branding);
    };
  };

  const handleBrandingSave = async () => {
    if (localBranding === null) {
      showSnack('Cant save branding, no branding selected', { variant: 'warning' });
      return;
    }
    if (!localBranding.id || localBranding?.id === null) {
      const { data: mutdata } = await addMutation.mutateAsync(localBranding);
      setSelectedBranding(mutdata);
      setLocalBranding(mutdata);
      showSnack('Saved branding, looking good! ❤️', { variant: 'success' });
    } else {
      await updateMutation.mutateAsync(localBranding);
      showSnack(`Updated branding, it looks nice! 😍`, { variant: 'success' });
    }
    if (currentSite) {
      await queryClient.invalidateQueries(['branding', (currentSite as Site)?.id]);
    }
  };

  const deleteBranding = async () => {
    if (isReadOnly) {
      showSnack(`Cant delete default branding`, { variant: 'warning' });
      return;
    }
    if (!localBranding?.id) {
      showSnack(`Cant delete branding, no branding selected`, { variant: 'warning' });
      return;
    }

    await deleteMutation.mutateAsync(localBranding?.id || '');
    await queryClient.invalidateQueries(['branding', (currentSite as Site)?.id]);
    setShowConfirmDelete(false);
    setLocalBranding(null);
    const defaultBranding = brandings?.find((b) => b.id === '52add555-843e-42cd-bba0-b2706e8d2f3b');
    if (defaultBranding) {
      setSelectedBranding(defaultBranding);
    }
    showSnack(`Deleted branding`, { variant: 'success' });
  };

  const handleDeleteButton = () => {
    setShowConfirmDelete(true);
  };
  const closeDialog = () => {
    setShowConfirmDelete(false);
  };

  const handleQrCodesToPrintChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQrCodesToPrint(e.target.value);
    if (e.target.value.split('\n').length > 0) {
      setPrintPng(false);
    }
  };

  const generatePreview = async (newBranding?: Branding | undefined) => {
    const addBranding = newBranding || localBranding;
    try {
      const fetchUrl = `${apiUrl}api/qr`;
      const fetchBody = {
        customBranding: addBranding,
        type: 1,
        addPrintingGuides: usePrintingGuides,
        responseFormat: 0,
        keys: ['preview'],
      };
      const a = await fetch(fetchUrl, {
        headers: { 'content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(fetchBody),
      });
      if (a.status !== 200) {
        throw new Error('Could not generate preview');
      }
      const body = await a.blob();
      setPreviewImage(URL.createObjectURL(body));
    } catch (error) {
      //Empty image in case of error
      setPreviewImage(
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
      );
      showSnack(
        'Could not generate preview, the server responded with an error. Try again later.',
        { variant: 'warning' },
      );
    }
  };

  useEffect(() => {
    if (brandings && brandings?.length > 0 && localBranding === null) {
      const firstBranding = brandings?.find((b) => b.id === '52add555-843e-42cd-bba0-b2706e8d2f3b');
      if (firstBranding) {
        setIsReadOnly(true);
        setSelectedBranding(firstBranding);
        const firstBrandingClone = structuredClone(firstBranding);
        setLocalBranding(structuredClone(firstBrandingClone));
      }
    }
    if (apiUrl) {
      void generatePreview();
    }
  }, [brandings, apiUrl]);

  return (
    <Paper variant="elevation" elevation={0}>
      <Stack direction="row" gap={2}>
        <Box sx={{ flex: '1' }}>
          <FormControl fullWidth>
            <FormLabel>Select a branding (or create new)</FormLabel>
            <ButtonGroup fullWidth>
              <Select
                sx={{ flex: '1 1 auto', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                size="small"
                value={selectedBranding?.id || ''}
                onChange={handleBrandingChange}
                fullWidth>
                {brandings?.map((d) => {
                  return (
                    <MenuItem key={d.id as string} value={d.id as string}>
                      {d?.name?.length > 0 ? d.name : 'Unnamed branding'}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                sx={{ width: 'auto' }}
                onClick={createNewBranding}
                color="info"
                aria-label="Create new template"
                endIcon={<Addchart />}>
                New
              </Button>
            </ButtonGroup>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              disabled={isReadOnly}
              size="small"
              fullWidth
              label="Name"
              onChange={handleChange('name')}
              onBlur={(e) => {
                if (e.target.value.length < 1) {
                  setLocalBranding({ ...localBranding, name: 'Unnamed branding' } as Branding);
                }
              }}
              value={localBranding?.name || ''}></TextField>
          </FormControl>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl sx={{ flex: '1 1 50%' }}>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                label="Background color"
                onChange={handleChange('backgroundColor')}
                value={localBranding?.backgroundColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth sx={{ flex: '1 1 50%' }}>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('foregroundColor')}
                label="Foreground color"
                value={localBranding?.foregroundColor || ''}></TextField>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('topBorderBranding.borderColor')}
                label="Top border color"
                value={localBranding?.topBorderBranding.borderColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('topBorderBranding.textColor')}
                label="Text color"
                value={localBranding?.topBorderBranding.textColor || ''}></TextField>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('bottomBorderBranding.borderColor')}
                label="Bottom border color"
                value={localBranding?.bottomBorderBranding.borderColor || ''}></TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                inputProps={{ type: 'color' }}
                size="small"
                fullWidth
                onChange={handleChange('bottomBorderBranding.textColor')}
                label="Text color"
                value={localBranding?.bottomBorderBranding.textColor || ''}></TextField>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <FormLabel>Top text size</FormLabel>
              <Slider
                min={20}
                step={1}
                max={100}
                valueLabelDisplay="auto"
                disabled={isReadOnly}
                aria-label="Top text size"
                value={localBranding?.topBorderBranding.textSize || 65}
                onChange={handleTextSizeChange('topBorderBranding')}
                color="info"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Bottom text size</FormLabel>
              <Slider
                min={20}
                step={1}
                max={100}
                valueLabelDisplay="auto"
                disabled={isReadOnly}
                aria-label="Bottom text size"
                value={localBranding?.bottomBorderBranding.textSize || 65}
                onChange={handleTextSizeChange('bottomBorderBranding')}
                color="info"
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                size="small"
                fullWidth
                onChange={handleChange('topBorderBranding.text')}
                label="Top border text"
                value={localBranding?.topBorderBranding.text || ''}></TextField>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                disabled={isReadOnly}
                size="small"
                fullWidth
                onChange={handleChange('bottomBorderBranding.text')}
                label="Bottom border text"
                value={localBranding?.bottomBorderBranding.text || ''}></TextField>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.topBorderBranding.imageUrl || ''}
                label={'Top border image'}
                onChange={handleChange('topBorderBranding.imageUrl')}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.bottomBorderBranding.imageUrl || ''}
                label={'Bottom border image'}
                onChange={handleChange('bottomBorderBranding.imageUrl')}
              />
            </Box>
          </Stack>
          <Stack direction="row" gap={2} sx={{ mt: 2 }}>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>Icon size</FormLabel>
                <Slider
                  min={4}
                  step={1}
                  max={24}
                  valueLabelDisplay="auto"
                  disabled={isReadOnly}
                  aria-label="Icon size"
                  value={localBranding?.iconSize || 4}
                  onChange={handleIconSizeChange}
                  color="info"
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ImageInput
                updatePreview={handleUpdatePreview}
                isReadOnly={isReadOnly}
                imageUrl={localBranding?.iconUrl || ''}
                label={'Icon url'}
                onChange={handleChange('iconUrl')}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Checkbox
                    value={usePrintingGuides}
                    onChange={(d) => setUsePrintingGuides(d.target.checked)}
                  />
                  Use printing guides
                </FormLabel>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormControl fullWidth>
                <FormLabel>
                  <Checkbox
                    value={printPng}
                    disabled={qrCodesToPrint.split('\n').length > 1}
                    onChange={(d) => setPrintPng(d.target.checked)}
                  />
                  Print using png
                </FormLabel>
              </FormControl>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                void generatePreview();
              }}
              variant="contained"
              color="info"
              startIcon={<CachedIcon />}>
              Update preview
            </Button>
          </Box>
        </Box>
        <Box sx={{ flex: '1' }}>
          <Stack direction="column" gap={2}>
            <FormControl sx={{ flex: '1' }}>
              <FormLabel>Selected qr codes to print</FormLabel>
              <TextField
                multiline={true}
                size="small"
                fullWidth
                id="selected-qr-codes"
                maxRows={6}
                onChange={handleQrCodesToPrintChange}
                value={qrCodesToPrint}
              />
            </FormControl>
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}>
              <img src={previewImage} style={{ aspectRatio: '69/97', maxWidth: '50%' }} />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ mt: 2 }}>
        <Stack direction="row" gap={2} sx={{ mt: 2 }}>
          <Stack direction="row" gap={2} flex="1">
            {!isReadOnly && (
              <Box sx={{ alignSelf: 'flex-start' }} display="flex">
                <Button
                  onClick={handleDeleteButton}
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForeverIcon />}>
                  Delete
                </Button>
              </Box>
            )}

            {!isReadOnly && (
              <Box justifyContent="flex-end" display="flex" flex="1">
                <Button onClick={handleBrandingSave} variant="contained" color="success">
                  Save branding
                </Button>
              </Box>
            )}
          </Stack>
          <Box flex="1" alignItems="flex-end" justifyContent="flex-end" display="flex">
            <ActionButton
              action={fetchPdf}
              variant="contained"
              color="info"
              disabled={qrCodesToPrint.length < 1}>
              Generate codes
            </ActionButton>
          </Box>
        </Stack>
      </Box>
      <Dialog
        open={showConfirmDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription">
        <DialogTitle id="alert-dialog-title">Delete branding</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this branding, it can't be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="outlined" color="warning" onClick={deleteBranding}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        transitionDuration={{ appear: 100, enter: 100, exit: 300 }}
        open={showPreview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-discription"
        onClick={closePreview}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0,0,0,0.8)' }}>
        <img src={previewUrl} alt="QR Code Preview" style={{ maxWidth: '80%', maxHeight: '80%' }} />
      </Backdrop>
    </Paper>
  );
};

export default QRDesign;
